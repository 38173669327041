const Data = [
  [
    {
      key: 1,
      name: "github",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-github",
    },

    {
      key: 2,
      name: "facebook",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-facebook",
    },

    {
      key: 3,
      name: "twitter",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-twitter",
    },
    {
      key: 4,
      name: "twitch",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-twitch",
    },
    {
      key: 5,
      name: "instagram",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-instagram",
    },

    {
      key: 6,
      name: "behance",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-behance",
    },
    {
      key: 7,
      name: "blogger",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-blogger",
    },
    {
      key: 8,
      name: "android",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-android",
    },

    {
      key: 9,
      name: "visa",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-cc-visa",
    },
    {
      key: 10,
      name: "aws",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-aws",
    },
    {
      key: 11,
      name: "discord",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-discord",
    },

    {
      key: 12,
      name: "git",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-git",
    },

    {
      key: 13,
      name: "react",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-react",
    },
    {
      key: 14,
      name: "bluetooth",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-bluetooth",
    },
    {
      key: 15,
      name: "bitcoin",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-bitcoin",
    },
    {
      key: 16,
      name: "chrome",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-chrome",
    },
    {
      key: 17,
      name: "stripe",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-cc-stripe",
    },
    {
      key: 18,
      name: "css",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-css3-alt",
    },
    {
      key: 18,
      name: "firefox",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-firefox",
    },

    {
      key: 20,
      name: "figma",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-figma",
    },
    {
      key: 21,
      name: "imdb",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-imdb",
    },
    {
      key: 22,
      name: "itunes",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-itunes",
    },
    {
      key: 23,
      name: "line",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-line",
    },
    {
      key: 24,
      name: "microsoft",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-microsoft",
    },
    {
      key: 25,
      name: "octopus",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-octopus-deploy",
    },
    {
      key: 26,
      name: "php",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-php",
    },
    {
      key: 27,
      name: "python",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-python",
    },
    {
      key: 28,
      name: "reddit",
      isFlipped: false,
      isMatched: false,
      url: "fa-brands fa-reddit",
    },
  ],

  [
    {
      key: 1,
      name: "a",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-a",
    },
    {
      key: 2,
      name: "b",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-b",
    },
    {
      key: 3,
      name: "c",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-c",
    },
    {
      key: 4,
      name: "d",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-d",
    },
    {
      key: 5,
      name: "e",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-e",
    },
    {
      key: 6,
      name: "f",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-f",
    },
    {
      key: 7,
      name: "g",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-g",
    },
    {
      key: 8,
      name: "h",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-h",
    },
    {
      key: 9,
      name: "i",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-i",
    },
    {
      key: 10,
      name: "j",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-j",
    },
    {
      key: 11,
      name: "k",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-k",
    },
    {
      key: 12,
      name: "l",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-l",
    },
    {
      key: 13,
      name: "m",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-m",
    },
    {
      key: 14,
      name: "n",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-n",
    },
    {
      key: 15,
      name: "o",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-o",
    },
    {
      key: 16,
      name: "p",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-p",
    },
    {
      key: 17,
      name: "q",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-q",
    },
    {
      key: 18,
      name: "r",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-r",
    },
    {
      key: 19,
      name: "s",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-s",
    },
    {
      key: 20,
      name: "t",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-t",
    },
    {
      key: 21,
      name: "u",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-u",
    },
    {
      key: 22,
      name: "v",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-v",
    },
    {
      key: 13,
      name: "w",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-w",
    },
    {
      key: 24,
      name: "x",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-x",
    },
    {
      key: 25,
      name: "y",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-y",
    },
    {
      key: 26,
      name: "z",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-z",
    },
  ],

  [
    {
      key: 1,
      name: "circle",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-circle",
    },
    {
      key: 2,
      name: "square",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-square",
    },
    {
      key: 3,
      name: "triangle",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-triangle-exclamation",
    },
    {
      key: 4,
      name: "rectangle",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-rectangle-list",
    },

    {
      key: 5,
      name: "moon",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-moon",
    },
    {
      key: 6,
      name: "right-arrow",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-arrow-right",
    },
    {
      key: 7,
      name: "left-arrow",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-arrow-left",
    },
    {
      key: 8,
      name: "arrow-up",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-arrow-up",
    },
    {
      key: 9,
      name: "arrow-down",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-arrow-down",
    },
    {
      key: 10,
      name: "star",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-star",
    },
    {
      key: 11,
      name: "heart",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-heart",
    },
    {
      key: 12,
      name: "cube",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-cube",
    },
    {
      key: 13,
      name: "diamond",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-diamond",
    },

    {
      key: 14,
      name: "cone",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-ice-cream",
    },
    {
      key: 15,
      name: "plus",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-plus",
    },
    {
      key: 16,
      name: "minus",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-minus",
    },
    {
      key: 17,
      name: "multiply",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-xmark",
    },
    {
      key: 18,
      name: "divide",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-divide",
    },
    {
      key: 19,
      name: "question",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-question",
    },
    {
      key: 20,
      name: "hash",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-hashtag",
    },
    {
      key: 21,
      name: "droplet",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-location-pin",
    },
    {
      key: 22,
      name: "play",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-play",
    },
    {
      key: 23,
      name: "slash",
      isFlipped: false,
      isMatched: false,
      url: "fa-solid fa-slash",
    },
  ],

  [
    {
      key: 100,
      name: "100",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 101,
      name: "101",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 102,
      name: "102",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 103,
      name: "103",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 104,
      name: "104",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 105,
      name: "105",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 106,
      name: "106",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 107,
      name: "107",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 108,
      name: "108",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 109,
      name: "109",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 110,
      name: "110",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 111,
      name: "111",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 112,
      name: "112",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 113,
      name: "113",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 114,
      name: "114",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 115,
      name: "115",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 116,
      name: "116",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 117,
      name: "117",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 118,
      name: "118",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 119,
      name: "119",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 120,
      name: "120",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 121,
      name: "121",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 122,
      name: "122",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 123,
      name: "123",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 124,
      name: "124",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 125,
      name: "125",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 126,
      name: "126",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 127,
      name: "127",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 128,
      name: "128",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 129,
      name: "129",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
    {
      key: 130,
      name: "130",
      isFlipped: false,
      isMatched: false,
      url: "",
    },
  ],
];

export default Data;
