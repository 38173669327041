import React, { useState } from "react";
import Homepage from "./components/Homepage";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Gamepage from "./components/Gamepage";
import {
  LevelContext,
  CardContext,
  CategoryContext,
  ColorContext,
} from "./components/Context";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Data from "./components/Data";

function App() {
  const [level, setLevel] = useState("Level 1");
  const [cardData, setCardData] = useState(Data);
  const [category, setCategory] = useState("Brands");
  const [theme, setTheme] = useState("default");

  let myStyle;
  if (theme === "Black") {
    myStyle = "#242124";
  }
  return (
    <>
      <ColorContext.Provider value={[theme, setTheme]}>
        <Header myStyle={myStyle} />
        <CategoryContext.Provider value={[category, setCategory]}>
          <LevelContext.Provider value={[level, setLevel]}>
            <CardContext.Provider value={[cardData, setCardData]}>
              <Router>
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={<Homepage myStyle={myStyle} />}
                  />
                  <Route
                    path="/game-page"
                    element={<Gamepage myStyle={myStyle} />}
                  />
                </Routes>
              </Router>
            </CardContext.Provider>
          </LevelContext.Provider>
        </CategoryContext.Provider>
        <Footer myStyle={myStyle} />
      </ColorContext.Provider>
    </>
  );
}

export default App;
