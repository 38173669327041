import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Card from "./card";
import Performance from "./Performance";
import { LevelContext, CardContext, CategoryContext } from "./Context";
import { availableLevels } from "./AvailableLevels";
import Confetti from "react-confetti";

function Gamepage({ myStyle }) {
  const [category, setCategory] = useContext(CategoryContext);
  const [level, setLevel] = useContext(LevelContext);
  const [cardData] = useContext(CardContext);
  const [cards, setCards] = useState([]);
  const [openCardCount, setOpenCardCount] = useState(0);
  const [userPerformance, setUserPerformance] = useState(Performance);

  const [currentMoves, setCurrentMoves] = useState(0);
  const [isWon, setIsWon] = useState(false);

  let shuffledCardArray;

  function getRandomCards(length, randomCardsArray, index) {
    let j = 0;
    let array = [];
    while (randomCardsArray.length !== length) {
      j = Math.floor(Math.random() * cardData[index].length);
      if (!randomCardsArray.includes(cardData[index][j])) {
        array.push(j);
        randomCardsArray.push(cardData[index][j]);
      }
    }
  }

  const [timer, setTimer] = useState({ minutes: 0, seconds: null });
  const [gameOver, setGameOver] = useState(false);

  function allocateTime() {
    availableLevels.forEach((availableLevel, index) => {
      if (level === availableLevel) {
        let overallTimeInSeconds = (index + 1) * 30;
        let newMinutes;
        let newSeconds;
        if (overallTimeInSeconds % 60 === 0) {
          newMinutes = overallTimeInSeconds / 60 - 1;
          newSeconds = 60;
          setTimer({ ...timer, minutes: newMinutes, seconds: newSeconds });
        } else if (overallTimeInSeconds % 60 === 30) {
          newMinutes = Math.floor(overallTimeInSeconds / 60);
          newSeconds = overallTimeInSeconds - newMinutes * 60;
          setTimer({ ...timer, minutes: newMinutes, seconds: newSeconds });
        } else {
          return;
        }
      }
    });
  }

  function makeCardQuantityBasedOnlevel(randomCardsArray, index) {
    availableLevels.forEach((availableLevel, levelIndex) => {
      if (level === availableLevel) {
        let length = levelIndex + 2;
        getRandomCards(length, randomCardsArray, index);
      }
      return;
    });
  }

  function loadGame() {
    allocateTime();
    let randomCardsArray = [];
    if (category === "Brands") {
      makeCardQuantityBasedOnlevel(randomCardsArray, 0);
    } else if (category === "Alphabets") {
      makeCardQuantityBasedOnlevel(randomCardsArray, 1);
    } else if (category === "Shapes") {
      makeCardQuantityBasedOnlevel(randomCardsArray, 2);
    } else {
      makeCardQuantityBasedOnlevel(randomCardsArray, 3);
    }

    const duplicateTheRandomCards = [...randomCardsArray, ...randomCardsArray];
    shuffledCardArray = duplicateTheRandomCards.sort(() => {
      return Math.random() - 0.5;
    });
    setCards(
      shuffledCardArray.map((card) => {
        return { ...card, isFlipped: true };
      })
    );
  }

  useEffect(() => {
    loadGame();
  }, [level]);

  const [resetTime, setResetTime] = useState(false);
  const timeoutRef = useRef();

  function startCountDown() {
    setTimer((prevTimer) => {
      return { ...prevTimer, seconds: prevTimer.seconds - 1 };
    });
    setResetTime(false);
  }

  function stopCountDown() {
    clearInterval(timeoutRef.current);
  }

  useEffect(() => {
    let timeout;
    if (!isWon) {
      timeout = setTimeout(() => {
        setCards((prevCards) => {
          return prevCards.map((card) => {
            return { ...card, isFlipped: false };
          });
        });
      }, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [level, resetTime, isWon]);

  useEffect(() => {
    allocateMaxMoves();
  }, [level]);

  useEffect(() => {
    if (!isWon && maxMoves === 0) {
      setGameOver(true);
    }
  });

  useEffect(() => {
    timeoutRef.current = setInterval(() => {
      startCountDown();
    }, 1000);

    return () => {
      clearInterval(timeoutRef.current);
    };
  }, [resetTime]);

  useEffect(() => {
    if (timer.minutes === 0 && timer.seconds === 0) {
      stopCountDown();
      setGameOver(true);
    }
    return () => {
      setGameOver(false);
    };
  }, [timer]);

  useEffect(() => {
    if (timer.seconds === 0 && timer.minutes !== 0) {
      setTimer((prevTimer) => {
        return { ...prevTimer, minutes: prevTimer.minutes - 1, seconds: 59 };
      });
    }
  }, [timer]);

  useEffect(() => {
    if (isWon || gameOver) {
      stopCountDown();
    }
  });

  const [maxMoves, setMaxMoves] = useState(null);
  function allocateMaxMoves() {
    availableLevels.forEach((availableLevel, index) => {
      if (level === availableLevel) {
        return setMaxMoves(index + 5);
      }
    });
  }

  function newGame() {
    if (gameOver || isWon) {
      loadGame();
      setCurrentMoves(0);
      setOpenCardCount(0);
      setGameOver(false);
      setIsWon(false);
      setResetTime(true);
      allocateMaxMoves();
    }
  }

  function nextLevel() {
    if (level === availableLevels[availableLevels.length - 1]) return;
    for (let i = 0; i < availableLevels.length; i++) {
      if (level === availableLevels[i]) {
        setLevel(availableLevels[i + 1]);
      }
    }
    setCurrentMoves(0);
    setOpenCardCount(0);
    setGameOver(false);
    setIsWon(false);
    setResetTime(true);
    allocateMaxMoves();
  }

  function resetStates() {
    setCards([]);
    setLevel("Level 1");
    setCategory("Brands");
  }

  let cardDivStyle;
  if (cards.length === 20) {
    cardDivStyle = {
      gridTemplateColumns: "repeat(5, 50px)",
    };
  } else if (cards.length === 24) {
    cardDivStyle = {
      gridTemplateColumns: "repeat(4, 50px)",
    };
  } else if (cards.length >= 30) {
    cardDivStyle = {
      gridTemplateColumns: "repeat(6, 40px)",
    };
  }

  function countUserMoves() {
    setCurrentMoves((prevMoves) => prevMoves + 1);
  }

  function revealCard(index) {
    if (openCardCount !== 2) {
      setCards((prevCards) => {
        return prevCards.map((card, i) => {
          if (i === index && openCardCount !== 2 && !card.isFlipped) {
            setOpenCardCount((prevCount) => prevCount + 1);
            return { ...card, isFlipped: true };
          } else {
            return card;
          }
        });
      });
    }
  }

  function checkIfCardsMatch() {
    let selectedCards = cards.filter((card) => {
      if (card.isFlipped && !card.isMatched) {
        return card;
      }
    });

    if (selectedCards.length === 2) {
      countUserMoves();
      setMaxMoves((prevMoves) => {
        return prevMoves - 1;
      });
      if (selectedCards[0].name === selectedCards[1].name) {
        setOpenCardCount(0);
        setCards(
          cards.map((card) => {
            if (
              card.name === selectedCards[0].name ||
              card.name === selectedCards[1].name
            ) {
              return { ...card, isMatched: true };
            } else {
              return card;
            }
          })
        );
      } else {
        setTimeout(() => {
          setCards(
            cards.map((card) => {
              if (
                card.name === selectedCards[0].name ||
                card.name === selectedCards[1].name
              ) {
                return { ...card, isFlipped: false };
              } else {
                return card;
              }
            })
          );
          setOpenCardCount(0);
        }, 1000);
      }
    }
  }

  useEffect(() => {
    checkIfCardsMatch();
  }, [cards]);

  let userMoves;

  function getPerformance(category) {
    availableLevels.forEach((availableLevel, index) => {
      if (level === availableLevel) {
        userMoves = category[index];
      }
    });
  }

  const { Brands, Alphabets, Shapes, Numbers } = userPerformance;

  if (category === "Brands") {
    getPerformance(Brands);
  } else if (category === "Alphabets") {
    getPerformance(Alphabets);
  } else if (category === "Shapes") {
    getPerformance(Shapes);
  } else {
    getPerformance(Numbers);
  }

  const previousBestScoreRef = useRef();

  function setPreviousBestScore(category) {
    availableLevels.forEach((availableLevel, index) => {
      if (level === availableLevel) {
        if (!category[index]) return;
        let { BestMoves } = category[index];
        previousBestScoreRef.current = BestMoves;
      }
    });
  }

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("Memoer"));
    if (!data) return;
    const { Brands, Alphabets, Shapes, Numbers } = data;
    if (!isWon) {
      if (category === "Brands") {
        setPreviousBestScore(Brands);
      } else if (category === "Alphabets") {
        setPreviousBestScore(Alphabets);
      } else if (category === "Shapes") {
        setPreviousBestScore(Shapes);
      } else {
        setPreviousBestScore(Numbers);
      }
    }
  }, [isWon, level]);

  useEffect(() => {
    let allCardsMatched;
    if (cards.length) {
      allCardsMatched = cards.every((card) => card.isMatched);
      if (allCardsMatched) {
        setIsWon(true);
      } else {
        setIsWon(false);
      }
    }
  }, [cards]);

  function setBestMoves(index) {
    if (category === "Brands") {
      setUserPerformance({
        ...userPerformance,
        Brands: userPerformance.Brands.map((performance, i) => {
          if (i === index) {
            let moves;
            if (performance.BestMoves === null) {
              moves = currentMoves;
            } else if (performance.BestMoves > currentMoves) {
              moves = currentMoves;
            } else {
              moves = performance.BestMoves;
            }
            return {
              ...performance,
              BestMoves: moves,
            };
          } else {
            return performance;
          }
        }),
      });
    } else if (category === "Alphabets") {
      setUserPerformance({
        ...userPerformance,
        Alphabets: userPerformance.Alphabets.map((performance, i) => {
          if (i === index) {
            let moves;
            if (performance.BestMoves === null) {
              moves = currentMoves;
            } else if (performance.BestMoves > currentMoves) {
              moves = currentMoves;
            } else {
              moves = performance.BestMoves;
            }
            return {
              ...performance,
              BestMoves: moves,
            };
          } else {
            return performance;
          }
        }),
      });
    } else if (category === "Shapes") {
      setUserPerformance({
        ...userPerformance,
        Shapes: userPerformance.Shapes.map((performance, i) => {
          if (i === index) {
            let moves;
            if (performance.BestMoves === null) {
              moves = currentMoves;
            } else if (performance.BestMoves > currentMoves) {
              moves = currentMoves;
            } else {
              moves = performance.BestMoves;
            }
            return {
              ...performance,
              BestMoves: moves,
            };
          } else {
            return performance;
          }
        }),
      });
    } else {
      setUserPerformance({
        ...userPerformance,
        Numbers: userPerformance.Numbers.map((performance, i) => {
          if (i === index) {
            let moves;
            if (performance.BestMoves === null) {
              moves = currentMoves;
            } else if (performance.BestMoves > currentMoves) {
              moves = currentMoves;
            } else {
              moves = performance.BestMoves;
            }
            return {
              ...performance,
              BestMoves: moves,
            };
          } else {
            return performance;
          }
        }),
      });
    }
  }

  useEffect(() => {
    if (isWon) {
      availableLevels.forEach((availableLevel, index) => {
        if (level === availableLevel) {
          setBestMoves(index);
        }
      });
    }
  }, [isWon]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("Memoer"));
    if (!data) return;
    if (data) {
      setUserPerformance(data);
    }
  }, []);

  useEffect(() => {
    if (isWon) {
      localStorage.setItem("Memoer", JSON.stringify(userPerformance));
    }
  });

  const [userWidth] = useState(window.innerWidth);

  return (
    <main className="main--gamepage">
      {isWon && <Confetti width={userWidth} tweenDuration={3000} />}
      <div className="level--div" style={{ backgroundColor: myStyle }}>
        <p>Category: {category}</p>
        <p>Level: {level}</p>
        <p>Best Moves: {userMoves && userMoves.BestMoves}</p>
        <p>Current Moves: {currentMoves}</p>
      </div>

      <div className="timer--div">
        <div className="minute">
          <h4>{timer.minutes < 10 ? `0${timer.minutes}` : timer.minutes}</h4>
          <p>Min</p>
        </div>
        :
        <div className="seconds">
          <h4>{timer.seconds < 10 ? `0${timer.seconds}` : timer.seconds}</h4>
          <p>Sec</p>
        </div>
      </div>
      <div className="maximum--moves">
        <p>Remaining Moves: {maxMoves}</p>
      </div>

      <div className="card--div" style={cardDivStyle}>
        {cards.map((card, index) => {
          return (
            <Card
              key={index}
              icon={card.url}
              cards={cards}
              category={category}
              card={card}
              revealCard={() => !gameOver && revealCard(index)}
              myStyle={myStyle}
            />
          );
        })}
      </div>
      <div className="buttons">
        <button
          style={{
            opacity: isWon || gameOver ? "1" : "0.6",
            backgroundColor: myStyle,
          }}
          onClick={newGame}
          className="newGame--button"
        >
          New Game
        </button>
        <button
          style={{
            opacity:
              level === availableLevels[availableLevels.length - 1]
                ? "0.5"
                : "1",
            backgroundColor: myStyle,
          }}
          onClick={nextLevel}
        >
          Next Level
        </button>
        <Link
          className="gamepage--link"
          to="/"
          style={{ color: myStyle, border: `1px solid ${myStyle}` }}
          onClick={resetStates}
        >
          Quit
        </Link>
      </div>
      {isWon && currentMoves < previousBestScoreRef.current && (
        <div className="best--score" style={{ backgroundColor: myStyle }}>
          <h2>Congratulations!</h2>
          <h3>New Best Score: {userMoves.BestMoves}</h3>
        </div>
      )}
      {isWon && currentMoves >= previousBestScoreRef.current && (
        <h4
          className="you--won"
          style={{ backgroundColor: myStyle, opacity: "0.8" }}
        >
          You Won!
        </h4>
      )}
      {!previousBestScoreRef.current && isWon && (
        <h4
          className="you--won"
          style={{ backgroundColor: myStyle, opacity: "0.8" }}
        >
          You Won!
        </h4>
      )}
      {gameOver && (
        <h4
          className="game--over"
          style={{ backgroundColor: myStyle, opacity: "0.8" }}
        >
          Game Over
        </h4>
      )}
    </main>
  );
}

export default Gamepage;
